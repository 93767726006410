.socialIcon {
    width: 32px;
    height: 32px;
    margin: 20px;
}

#skillsList > p {
    margin: 0 20px;
    width: auto;
}
