@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    color-scheme: dark;
    scroll-behavior: smooth;
}

html, body, #root {
    height: 100%;
}

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    color: white;
}

h1, p {
    color: white;
}

p {
    width: 50%;
    text-align: center;
}

a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    transition: color 0.25s;
}

    a:hover {
        color: lightgray;
    }

.fullScreenContainer {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.fullScreenImage {
    position: absolute;

    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fullScreenOverlay {
    position: absolute;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: rgba(23, 23, 23, 0.7);
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

