.header {
    position: fixed;
    bottom: 0;
    z-index: 2;

    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    background-color: rgba(23, 23, 23, 0.7);
}

.header > a {
    margin: 0 50px;
}
